.okayNav-header {
  height: 7rem;
  background-color: #fff;
}
.okayNav a {
  color: #2e2e33;
  font-size: 1.4rem;
  font-weight: bold;
}
.okayNav a:hover {
  color: #546edb;
}
.okayNav__nav--visible a:hover {
  color: #546edb;
}
.okayNav__nav--invisible {
  background: #fff;
}
.okayNav__menu-toggle span {
  background: #2e2e33;
}
.okayNav__menu-toggle.icon--active span {
  background: #546edb;
}
